<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('common.camera_groups') }}</span>
    <el-button
      slot="header-actions"
      name="create-btn"
      class="header-button"
      circle
      icon="el-icon-plus"
      size="small"
      :disabled="$hasNoPermission('ffsecurity.add_cameragroup')"
      @click="$router.push({ path: '/camera-groups/create/' })"
    />
    <el-table
      slot="content"
      name="camera-groups-table"
      class="camera-groups-table table-medium"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      @row-click="rowClick"
    >
      <el-table-column prop="id" :label="$tf('common.id')" width="60"></el-table-column>
      <el-table-column prop="name" :label="$tf('common.name')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link
          ><br />
          {{ row.comment }}
        </template>
      </el-table-column>
      <el-table-column prop="active" :label="$tf('common.active,,1')" width="120" align="center">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/layout';

export default {
  name: 'page-camera-groups',
  components: {
    PageLayout
  },
  computed: {
    state() {
      return this.$store.state.camera_groups;
    }
  },
  mounted() {
    this.$store.dispatch(this.state.Action.Get);
  },
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return '/camera-groups/' + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    }
  }
};
</script>
